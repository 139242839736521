<template>
    <span class="component-customerDialog" @click="show">
        <slot></slot>
        <el-dialog
            v-if="visible"
            class="m-dialog--comDialog"
            :custom-class="className"
            :title="title"
            :visible="true"
            append-to-body
            :width="width"
            :before-close="willClose"
            :top="top"
            :close-on-click-modal="closeOnClickModal"
            :close-on-press-escape="closeOnPressEscape"
            :show-close="showClose"
            @close="close"
        >
            <el-scrollbar class="m-scroll--comDialog hiddenX" :style="scrollStyle">
                <component
                    :is="component"
                    v-if="showSelfComponent"
                    v-bind="$attrs"
                    v-on="$listeners"
                    @closeDialog="close"
                ></component>
                <slot name="body" :close="close"></slot>
            </el-scrollbar>
            <template slot="footer">
                <slot name="footer"></slot>
            </template>
        </el-dialog>
    </span>
</template>

<script>
import { isFunction } from "@utils/utils";
export default {
    name: "com-dialog",
    props: {
        width: {
            type: String,
            default: "50%"
        },
        top: {
            type: String,
            default: "5vh"
        },
        height: {
            type: String // vh,px, 'auto' 1.不传默认75vh，2.传auto：自适，3.高度
        },
        className: {
            type: String
        },
        component: {
            // 加载哪个组件,可传全局组件或者通过slot
            type: String
        },
        title: {
            // 弹窗标题
            type: String,
            default: "详情"
        },
        beforeClose: {
            type: Function
        },
        beforeShow: {
            type: Function
        },
        scrollClassName: String,
        closeOnClickModal: { type: Boolean, default: true },
        closeOnPressEscape: { type: Boolean, default: true },
        showClose: { type: Boolean, default: true }
    },
    data() {
        return {
            visible: false,
            showSelfComponent: false
        };
    },
    computed: {
        scrollStyle() {
            let ret = {};
            if (this.height) {
                ret.height = this.height;
            }
            return ret;
        }
    },
    created() {
        if (this.component && !this.$slots.body) {
            this.showSelfComponent = true;
        }
    },
    methods: {
        show() {
            if (isFunction(this.beforeShow) && !this.beforeShow()) {
                return;
            }
            this.visible = true;
        },
        close() {
            this.visible = false;
        },
        willClose(done) {
            if (isFunction(this.beforeClose) && !this.beforeClose()) {
                return;
            }
            done();
        }
    }
};
</script>

<style scoped lang="scss">
.com-height {
    height: 75vh;
}
</style>
